import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = ["workGroup",
					"establishmentOrDivision",
					"employeeType",
					"employeeShift",
					"department",
					"invitedEmployees"]

  invite(event) {
    event.preventDefault()

    let workGroupId = this.workGroupTarget.value
    fetch(Routes.training_employees_path(this.data.get("accountId"), {work_group: workGroupId,
																	  establishment_or_division: this.establishmentOrDivisionTarget.value,
																	  employee_type: this.employeeTypeTarget.value,
																	  employee_shift: this.employeeShiftTarget.value,
																	  department: this.departmentTarget.value,
																	  format: "json"}),
		  {
			method: "GET",
			credentials: "same-origin",
			headers: {
			  "X-CSRF-Token": getCSRF()}
		  })
      .then( response => {
		if (response.ok) {
		  return response.json()
		}
      })
      .then( data => {
		data.forEach( employee => {
		  let employee_id = employee.id
		  this.invitedEmployeesTarget.querySelector(`[value="${employee_id}"]`).selected = true
		})
		$(this.invitedEmployeesTarget).trigger("chosen:updated")
		this.workGroupTarget.value = ""
		this.departmentTarget.value = ""
		this.establishmentOrDivisionTarget.value = ""
		this.employeeTypeTarget.value = ""
		this.employeeShiftTarget.value = ""
      })
  }
}

import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
    "bulkForm",
    "notes",
    "jobQualification",
    "submitButton",
    "employee",
    "inviteesSelectedCount",
    "establishmentSelect",
    "inviteesTable",
    "noFilterData",
  ]

  initialize() {
    if (this.hasBulkFormTarget &&
	this.hasSubmitButtonTarget) {
      this.employeeWasChecked()
    }

    // Disable all employee experience level selects.
    document.querySelectorAll(".js-eel-select").forEach( el => {
      el.disabled = true
    })
  }

  checkAll() {
    this.filteredInviteeCheckboxes().forEach( employee => {
      employee.checked = true
    })
    this.employeeWasChecked()
  }

  uncheckAll() {
    this.filteredInviteeCheckboxes().forEach( employee => {
      employee.checked = false
    })
    this.employeeWasChecked()
  }

  // Enable/disable the eel select for the appropriate JQ
  toggleJobQualification(event) {
    let eelSelect = event.target.nextElementSibling.querySelector("select")
    if (eelSelect) {
      if (event.target.checked) {
	eelSelect.disabled = false
      } else {
	eelSelect.disabled = true
      }
    }
  }
  
  employeeWasChecked() {
    let employeeCount = this.checkedEmployeeCount()
    this.submitButtonTarget.disabled = employeeCount == 0
    this.submitButtonTarget.textContent = `Record Attendance for ${employeeCount} ${employeeCount == 1 ? "Invitee" : "Invitees"}`
    this.inviteesSelectedCountTarget.textContent = employeeCount
  }

  filterByEstablishment() {
    var establishmentIdValue = this.establishmentSelectTarget.value
    var zebraStripe = false
    var emptyFilter = true
    
    if (establishmentIdValue == "") {
      // All establishments, show all
      this.inviteesTableTarget.querySelectorAll("tbody tr").forEach( row => {
	row.style.display = "table-row"
	zebraStripe = this.zebraRow(row, zebraStripe)
	emptyFilter = false
      })
    } else if (establishmentIdValue == "unassigned") {
      // Unassigned employees, have no establishments
      this.inviteesTableTarget.querySelectorAll("tbody tr").forEach( row => {
	var establishmentIds = JSON.parse(row.dataset.employeeEstablishmentIds)
	if (establishmentIds.length == 0) {
	  row.style.display = "table-row"
	  zebraStripe = this.zebraRow(row, zebraStripe)
	  emptyFilter = false
	} else {
	  row.style.display = "none"
	}
      })
    } else {
      var establishmentId = parseInt(establishmentIdValue.split("_")[1])
      this.inviteesTableTarget.querySelectorAll("tbody tr").forEach( row => {
	var establishmentIds = JSON.parse(row.dataset.employeeEstablishmentIds)
	if (establishmentIds.includes(establishmentId)) {
	  row.style.display = "table-row"
	  zebraStripe = this.zebraRow(row, zebraStripe)
	  emptyFilter = false
	} else {
	  row.style.display = "none"
	}
      })
    }

    if (emptyFilter) {
      this.inviteesTableTarget.classList.add("hidden")
      this.noFilterDataTarget.classList.remove("hidden")
    } else {
      this.inviteesTableTarget.classList.remove("hidden")
      this.noFilterDataTarget.classList.add("hidden")
    }
  }

  submit() {
    let employeeIds = this.employeeTargets.filter( employeeCheck => {
      return employeeCheck.checked
    }).map( checkedEmployee => {
      return checkedEmployee.value
    })
    let trainingRecords = this.jobQualificationTargets.filter( jobQualificationCheck => {
      return jobQualificationCheck.checked
    }).map ( checkedJobQualification => {
      let eelValue
      let eelSelectList = document.getElementsByName(checkedJobQualification.dataset.eelName) // returns a NodeList
      if (eelSelectList.length > 0) {
	eelValue = eelSelectList[0].value
      } else {
	eelValue = null
      }
      return {
	job_qualification_id: checkedJobQualification.value,
	employee_experience_level: eelValue,
      }
    })

    // Disable the submit button, then reenable on completion
    this.submitButtonTarget.disabled = true
    fetch(Routes.training_completed_course_path(this.data.get("accountId"), this.data.get("courseSessionId")), {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
    	"X-CSRF-Token": getCSRF(),
    	"Content-Type": "application/json"},
      body: JSON.stringify({employees: employeeIds, training_records: trainingRecords, notes: this.notesTarget.value})
    })
      .then( response => {
    	if (response.ok) {
	  this.submitButtonTarget.disabled = false
    	  location.reload(true)
    	}
      })
      .catch ( error => {
    	console.log("Server error occurred")
	// Do not do this in finally, otherwise the button may be reenabled before the page reload and double submits happen
	this.submitButtonTarget.disabled = false
      })
  }

  filteredInviteeCheckboxes() {
    var establishmentIdValue = this.establishmentSelectTarget.value

    if (establishmentIdValue == "") {
      return this.employeeTargets
    } else if (establishmentIdValue == "unassigned") {
      return Array.from(
	this.inviteesTableTarget
	  .querySelectorAll("tbody tr"))
	.filter( row => JSON.parse(row.dataset.employeeEstablishmentIds).length == 0 ) 
	.map( row => row.querySelector("input[type='checkbox']"))
    } else {
      var establishmentId = parseInt(establishmentIdValue.split("_")[1])
      return Array.from(
	this.inviteesTableTarget
	  .querySelectorAll("tbody tr"))
	.filter( row => JSON.parse(row.dataset.employeeEstablishmentIds).includes(establishmentId))
	.map( row => row.querySelector("input[type='checkbox']"))}
  }

  // Counts all employees regardless of filtering
  checkedEmployeeCount() {
    return this.employeeTargets.map( function(check) {
      return check.checked ? 1 : 0
    }).reduce( function(sum, x) {
      return sum + x
    }, 0)
  }

  zebraRow(row, zebraStripe) {
    if (zebraStripe) {
      row.classList.add("zebra-stripe")
    } else {
      row.classList.remove("zebra-stripe")
    }
    return !zebraStripe
  }
}

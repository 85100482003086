import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "employeeInput",
    "fileUploads",
    "trainingSchedule",
    "documents",
    "safetyIncidentSubmittal",
    "transportationIncidents",
  ]

  enableAllEmployeeInput() {
    this.employeeInputTargets.forEach( check => {
      check.checked = true
    })
  }

  enableAllFileUploads() {
    this.fileUploadsTargets.forEach( check => {
      check.checked = true
    })
  }

  enableAllTrainingSchedule() {
    this.trainingScheduleTargets.forEach( check => {
      check.checked = true
    })
  }

  enableAllTransportationIncidents() {
    this.transportationIncidentsTargets.forEach( check => {
      check.checked = true
    })
  }

  enableAllDocuments() {
    this.documentsTargets.forEach( check => {
      check.checked = true
    })
  }

  enableAllSafetyIncidentSubmittal() {
    this.safetyIncidentSubmittalTargets.forEach( check => {
      check.checked = true
    })
  }

  disableAllEmployeeInput() {
    this.employeeInputTargets.forEach( check => {
      check.checked = false
    })
  }

  disableAllFileUploads() {
    this.fileUploadsTargets.forEach( check => {
      check.checked = false
    })
  }

  disableAllTrainingSchedule() {
    this.trainingScheduleTargets.forEach( check => {
      check.checked = false
    })
  }

  disableAllTransportationIncidents() {
    this.transportationIncidentsTargets.forEach( check => {
      check.checked = false
    })
  }

  disableAllDocuments() {
    this.documentsTargets.forEach( check => {
      check.checked = false
    })
  }

  disableAllSafetyIncidentSubmittal() {
    this.safetyIncidentSubmittalTargets.forEach( check => {
      check.checked = false
    })
  }
}

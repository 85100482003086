import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = ["courseSelect",
					"hiddenCourseId",
					"invitedEmployees",
					"workGroup",
					"employeeTemplate",
					"noSuggestionsTemplate",
					"suggestedInvitees",
					"establishmentOrDivisionSelect",
					"jobQualificationsList",
					"employeeType",
					"employeeShift",
					"department"]

  initialize() {
    this.suggestedEmployees = [] // will be set by the filtersChanged event
	this.jobQualifications = [] // will be set by the filtersChangdd event
    this.filtersChanged()
  }

  filtersChanged() {
    var courseId = this.getCourseId()

    // Cancel any existing fetches of suggested employees
    if (this.abortSuggestController) { 
      this.abortSuggestController.abort()
      this.abortSuggestController = null
    }

	// Cancel any existing fetches of job qualifications
	if (this.abortCourseController) {
	  this.abortCourseController.abort()
	  this.abortCourseController = null
	}

	// Cancel any existing fetches of instructors
	if (this.abortInstructorsController) {
	  this.abortInstructorsController.abort()
	  this.abortInsrructorsController = null
	}
	
    // Notify of loading
    this.suggestedInviteesTarget.innerHTML = "Loading..."
    
    if (courseId) {
      this.abortSuggestController = new AbortController()
      fetch(Routes.training_course_suggested_employees_path(this.data.get("accountId"), courseId, {work_group_id: this.workGroupTarget.value,
																								   establishment_or_division: this.establishmentOrDivisionSelectTarget.value,
																								   employee_type: this.employeeTypeTarget.value,
																								   employee_shift: this.employeeShiftTarget.value,
																								   department: this.departmentTarget.value}), {
		method: "GET",
		credentials: "same-origin",
		headers: {
		  "X-CSRF-Token": getCSRF()},
		signal: this.abortSuggestController.signal
      })
		.then( response => {
		  if (response.ok) {
			return response.json()
		  }
		})
		.then( data => {
		  this.suggestedEmployees = data
		  this.renderSuggestedEmployees()
		})

	  this.abortCourseController = new AbortController()
	  fetch(Routes.training_course_path(this.data.get("accountId"), courseId, {format: "json"}), {
		method: "GET",
		credentials: "same-origin",
		headers: {
		  "Content-Type": "application/json",
		  "X-CSRF-Token": getCSRF()},
		signal: this.abortCourseController.signal
	  })
		.then( response => {
		  if (response.ok) {
			return response.json()
		  }
		})
		.then( data => {
		  this.jobQualifications = data["job_qualifications"]
		  this.renderJobQualifications(courseId)
		})
		.catch( error => {
		  console.log(error)
		})
    } else {
      // course id is blank, empty out suggested employees
      this.suggestedEmployees = []
      this.renderSuggestedEmployees()

	  this.jobQualificatiosn = []
	  this.renderJobQualifications("")
    }
  }

  renderSuggestedEmployees() {
    // Need to remove all already selected employees from the suggested employees array before rendering
    let selectedEmployees = Array.from(this.invitedEmployeesTarget.selectedOptions).map( opt => parseInt(opt.value) )
    let unselectedSuggestedEmployees = this.suggestedEmployees.filter( employee => !selectedEmployees.includes(employee.id))
    if (unselectedSuggestedEmployees.length > 0) {
      let t = this.employeeTemplateTarget.content
      let employeeList = unselectedSuggestedEmployees.reduce( function(employees, employee) {
	let li = t.cloneNode(true)
	li.querySelector(".employee-name").textContent = employee["select_name"]
	li.querySelector("button").dataset.employeeId = employee.id
	employees.appendChild(li)
	return employees
      }, document.createDocumentFragment())
      this.suggestedInviteesTarget.innerHTML = ''
      this.suggestedInviteesTarget.appendChild(employeeList)
    } else {
      let t = this.noSuggestionsTemplateTarget.content
      let noSuggestionsList = t.cloneNode(true)
      this.suggestedInviteesTarget.innerHTML = ''
      this.suggestedInviteesTarget.appendChild(noSuggestionsList)
    }
  }

  renderJobQualifications(courseId) {
	// Hide job qualifications when not selected
	if (courseId == "") {
	  this.jobQualificationsListTarget.classList.add("hidden")
	} else {
	  // Show job qualifications
	  this.jobQualificationsListTarget.classList.remove("hidden")

	  let noneMessage = this.jobQualificationsListTarget.querySelector("p")
	  let ul = this.jobQualificationsListTarget.querySelector("ul")

	  if (this.jobQualifications.length == 0) {
		// No jqs, show the warning
		ul.classList.add("hidden")
		noneMessage.classList.remove("hidden")
	  } else {		
		// Some jqs, show the list
		ul.classList.remove("hidden")
		noneMessage.classList.add("hidden")

		ul.innerHTML = ''
		this.jobQualifications.map( jq => jq["name"] ).sort().forEach( jq => {
		  let li = document.createElement("li")
		  li.textContent = jq
		  li.classList.add("font-bold")
		  ul.appendChild(li)
		})
	  }
	}
  }
  
  invite(event) {
    event.preventDefault()
    let employeeId = event.target.dataset.employeeId
    this.invitedEmployeesTarget.querySelector(`option[value='${employeeId}']`).selected = true
    $(this.invitedEmployeesTarget).trigger("chosen:updated")

    // Trigger a redraw of suggested employees in case the list is no empty and needs to change to display such
    this.renderSuggestedEmployees()
  }

  inviteesChanged(event) {
    // Can't tell if employees were added or removed, so we will rerender the suggested employees instead
    this.renderSuggestedEmployees()
  }

  clearFilters(event) {
	event.preventDefault()
	this.workGroupTarget.value = ""
	this.establishmentOrDivisionSelectTarget.value = ""
	this.employeeTypeTarget.value = ""
	this.employeeShiftTarget.value = ""
	this.departmentTarget.value = ""
	this.filtersChanged()
  }

  // For new records, courseId is taken from the select. For existing records which no select
  // the course record should be specified in the controller data
  getCourseId() {
	if(this.hasCourseSelectTarget) {
	  return this.courseSelectTarget.value
	} else if (this.hasHiddenCourseIdTarget) {
	  return this.hiddenCourseIdTarget.value
	} else {
	  return null
	}
  }
}
